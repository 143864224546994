import React from "react";

import Layout from "../components/layout";
import Title from "../components/title";
import SEO from "../components/seo";

// import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import { BsClockHistory } from "react-icons/bs";
// Styles
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import * as styles from "../styles/pages/experiences.module.scss";

const Experiences = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulExperiences {
          edges {
            node {
              description {
                raw
              }
              duration
              images {
                gatsbyImageData(
                  quality: 100
                  height: 200
                  width: 300
                  layout: FULL_WIDTH
                )
              }
              price
              slug
              node_locale
              title
            }
          }
        }
      }
    `
  );

  const Bold = ({ children }) => (
    <span>
      <strong>{children}</strong>
    </span>
  );
  const Text = ({ children }) => <p className={styles.text}>{children}</p>;

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImageData, description } = node.data.target;

        return (
          <div>
            <GatsbyImage image={gatsbyImageData} alt={description} />
          </div>
        );
      },
    },
  };

  return (
    <Layout>
      <Title picture title="Experiences" />
      <SEO title="Experiences" />
      <Container className="mt-5 pt-5">
        <Row className="g-5">
          {data.allContentfulExperiences.edges.map(({ node }, idx) => {
            return (
              <Col md={4}>
                <Card
                  className={`${styles.card} p-0`}
                  onClick={() => navigate(`/experiences/${node.slug}`)}
                >
                  <GatsbyImage
                    image={node.images[0].gatsbyImageData}
                    alt={node.title}
                  />

                  <Card.Body className="p-4">
                    <Card.Title>{node.title}</Card.Title>
                    <Card.Text className="mb-0 small">
                      {node.duration && (
                        <span>
                          <BsClockHistory className="me-1" /> {node.duration}
                        </span>
                      )}
                    </Card.Text>
                    <hr />
                    <Card.Text className={styles.cardText}>
                      {/* {node.description.raw.slice(75, 300)}... */}
                      {renderRichText(node.description, options)}
                    </Card.Text>
                    <Button variant="secondary-rounded">Read More</Button>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Layout>
  );
};

export default Experiences;
